<template>
    <div id="app">
        <div class="title-bar">
            <div class="content-box">
                <div class="nav-box">
                    <img class="logo" src="./assets/logo.png" alt="">
                    <div class="loc-box">
                        <img src="./assets/loc.png" alt="">
                        厦门
                    </div>
                    <div class="nav">
                        <div class="nav-list cur">
                            首页
                        </div>
                    </div>
                </div>
                <div class="nav-right">
                    <!--                    <div>-->
                    <!--                        个人中心-->
                    <!--                    </div>-->
                    <el-button class="my-btn" @click="setModalShow">免费发布信息</el-button>
                    <!--                    <div>帮助中心</div>-->
                    <!--                    <div>联系客服</div>-->
                </div>
            </div>
        </div>
        <div class="banner-box">
            <div class="banner">
                <img class="banner-text" src="./assets/banner-text.png" alt="">
                <img class="banner-ren" src="./assets/ren.png" alt="">
            </div>
        </div>
<!--        <div class="search-bar">-->
<!--            <div class="search-box">-->
<!--                <el-input v-model="value1" placeholder="请搜索职位/公司"></el-input>-->
<!--            </div>-->
<!--            <a>搜索</a>-->
<!--        </div>-->
        <div class="title-h2">
            <h2>
                <span>信息广场</span>
            </h2>
        </div>
        <div class="message-bar">
            <h3>信息列表</h3>
            <div class="message-box">
                <div class="message-list" v-for="(item, index) in missageList" :key="index">
                    <div>
                        <h3>职位名称：{{item.name}} <span v-if="item.isSoom" style="color: red;border: 1px solid red;font-size: 12px">加急</span></h3>
                        <h3>招聘企业：{{item.comName}}</h3>
                    </div>
                    <div>
                        <p>联系人：{{item.userName}}</p>
                        <p>联系邮箱：<a :href="'mailto:'+item.email">{{item.email}}</a></p>
                        <p>联系电话：<a :href="'tel:'+item.phone">{{item.phone.slice(0,3)}}****{{item.phone.slice(7,11)}}</a></p>
                    </div>
                    <p style="height: 100%;overflow: hidden;
display: -webkit-box;
-webkit-line-clamp: 3;
-webkit-box-orient: vertical;" :title="item.desc">职位要求：{{item.desc}}</p>
                </div>
            </div>
        </div>
        <div class="content-banner-bar">
            <div class="search-list">
                <div>
                    <div>
                        <p>技术</p>
                        <span>C++</span>
                        <span>前端开发工程师</span>
                        <span>测试工程师</span>
                    </div>
                </div>
                <div>
                    <div>
                        <p>销售</p>
                        <span>销售专员</span>
                        <span>销售经理</span>
                        <span>销售主管</span>
                    </div>
                </div>
                <div>
                    <div>
                        <p>金融</p>
                        <span>投资经理</span>
                        <span>投资总监/VP</span>
                        <span>风控</span>
                    </div>
                </div>
                <div>
                    <div>
                        <p>教育培训</p>
                        <span>课程设计</span>
                        <span>教务管理</span>
                        <span>IT培训</span>
                    </div>
                </div>
                <div>
                    <div>
                        <p>传媒</p>
                        <span>广告文案</span>
                        <span>广告创意设计</span>
                        <span>编辑</span>
                        <span>摄影</span>
                    </div>
                </div>
                <div>
                    <div>
                        <p>产品</p>
                        <span>UI设计师</span>
                        <span>平面设计</span>
                        <span>交互设计师</span>
                    </div>
                </div>
                <div>
                    <div>
                        <p>运营</p>
                        <span>新媒体运营</span>
                        <span>产品运营</span>
                        <span>网络推广</span>
                    </div>
                    <el-icon type="icon-right"></el-icon>
                </div>
<!--                <div class="search-list-footer">-->
<!--                    <span>1/3</span>-->
<!--                    <div>-->
<!--                        <el-icon class="el-icon-caret-left"></el-icon>-->
<!--                        <el-icon class="el-icon-caret-right"></el-icon>-->
<!--                    </div>-->
<!--                </div>-->
            </div>
            <div class="content-banner-center">
                <img src="./assets/banner1.png" alt="">
                <img src="./assets/banner2.png" alt="">
            </div>
            <div class="content-banner-right">
                <img src="./assets/banner3.png" alt="">
                <img src="./assets/banner.png" alt="">
                <img src="./assets/banner5.png" alt="">
            </div>
        </div>
<!--        <div class="title-h2">-->
<!--            <h2>-->
<!--                <span>热招职位</span>-->
<!--            </h2>-->
<!--        </div>-->
<!--        <div class="position-bar">-->
<!--            <el-tabs v-model="curNavName">-->
<!--                <el-tab-pane label="IT互联网" name="first">-->
<!--                    <div class="position-box">-->
<!--                        <div class="position-list" v-for="(item, index) in positionList.IT" :key="index">-->
<!--                            <div class="header">-->
<!--                                <h4>{{item.name}}-->
<!--                                    <el-tag v-if="item.status">在线</el-tag>-->
<!--                                </h4>-->
<!--                                <span class="cash">{{item.cash}}</span></div>-->
<!--                            <div class="tag-bar">-->
<!--                                <el-tag type="info" style="margin-right: 5px;" size="small"-->
<!--                                        v-for="(_item, index) in item.tags" :key="'item'+index">{{_item}}-->
<!--                                </el-tag>-->
<!--                            </div>-->
<!--                            <div class="gongsi-detial">-->
<!--                                <div>-->
<!--                                    <img :src="item.logo" alt="">-->
<!--                                    {{item.gongsiName}}-->
<!--                                </div>-->
<!--                                <div>-->
<!--                                    {{item.detail}}-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </el-tab-pane>-->
<!--                <el-tab-pane label="金融" name="second">-->
<!--                    <div class="position-box">-->
<!--                        <div class="position-list" v-for="(item, index) in positionList.IT" :key="index">-->
<!--                            <div class="header">-->
<!--                                <h4>{{item.name}}-->
<!--                                    <el-tag v-if="item.status">在线</el-tag>-->
<!--                                </h4>-->
<!--                                <span class="cash">{{item.cash}}</span></div>-->
<!--                            <div class="tag-bar">-->
<!--                                <el-tag type="info" style="margin-right: 5px;" size="small"-->
<!--                                        v-for="(_item, index) in item.tags" :key="'item'+index">{{_item}}-->
<!--                                </el-tag>-->
<!--                            </div>-->
<!--                            <div class="gongsi-detial">-->
<!--                                <div>-->
<!--                                    <img :src="item.logo" alt="">-->
<!--                                    {{item.gongsiName}}-->
<!--                                </div>-->
<!--                                <div>-->
<!--                                    {{item.detail}}-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </el-tab-pane>-->
<!--                <el-tab-pane label="教育培训" name="third">-->
<!--                    <div class="position-box">-->
<!--                        <div class="position-list" v-for="(item, index) in positionList.IT" :key="index">-->
<!--                            <div class="header">-->
<!--                                <h4>{{item.name}}-->
<!--                                    <el-tag v-if="item.status">在线</el-tag>-->
<!--                                </h4>-->
<!--                                <span class="cash">{{item.cash}}</span></div>-->
<!--                            <div class="tag-bar">-->
<!--                                <el-tag type="info" style="margin-right: 5px;" size="small"-->
<!--                                        v-for="(_item, index) in item.tags" :key="'item'+index">{{_item}}-->
<!--                                </el-tag>-->
<!--                            </div>-->
<!--                            <div class="gongsi-detial">-->
<!--                                <div>-->
<!--                                    <img :src="item.logo" alt="">-->
<!--                                    {{item.gongsiName}}-->
<!--                                </div>-->
<!--                                <div>-->
<!--                                    {{item.detail}}-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </el-tab-pane>-->
<!--                <el-tab-pane label="娱乐传媒" name="fourth">-->
<!--                    <div class="position-box">-->
<!--                        <div class="position-list" v-for="(item, index) in positionList.IT" :key="index">-->
<!--                            <div class="header">-->
<!--                                <h4>{{item.name}}-->
<!--                                    <el-tag v-if="item.status">在线</el-tag>-->
<!--                                </h4>-->
<!--                                <span class="cash">{{item.cash}}</span></div>-->
<!--                            <div class="tag-bar">-->
<!--                                <el-tag type="info" style="margin-right: 5px;" size="small"-->
<!--                                        v-for="(_item, index) in item.tags" :key="'item'+index">{{_item}}-->
<!--                                </el-tag>-->
<!--                            </div>-->
<!--                            <div class="gongsi-detial">-->
<!--                                <div>-->
<!--                                    <img :src="item.logo" alt="">-->
<!--                                    {{item.gongsiName}}-->
<!--                                </div>-->
<!--                                <div>-->
<!--                                    {{item.detail}}-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </el-tab-pane>-->
<!--                <el-tab-pane label="法律咨询" name="fourth1">-->
<!--                    <div class="position-box">-->
<!--                        <div class="position-list" v-for="(item, index) in positionList.IT" :key="index">-->
<!--                            <div class="header">-->
<!--                                <h4>{{item.name}}-->
<!--                                    <el-tag v-if="item.status">在线</el-tag>-->
<!--                                </h4>-->
<!--                                <span class="cash">{{item.cash}}</span></div>-->
<!--                            <div class="tag-bar">-->
<!--                                <el-tag type="info" style="margin-right: 5px;" size="small"-->
<!--                                        v-for="(_item, index) in item.tags" :key="'item'+index">{{_item}}-->
<!--                                </el-tag>-->
<!--                            </div>-->
<!--                            <div class="gongsi-detial">-->
<!--                                <div>-->
<!--                                    <img :src="item.logo" alt="">-->
<!--                                    {{item.gongsiName}}-->
<!--                                </div>-->
<!--                                <div>-->
<!--                                    {{item.detail}}-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </el-tab-pane>-->
<!--                <el-tab-pane label="采购贸易" name="fourth2">-->
<!--                    <div class="position-box">-->
<!--                        <div class="position-list" v-for="(item, index) in positionList.IT" :key="index">-->
<!--                            <div class="header">-->
<!--                                <h4>{{item.name}}-->
<!--                                    <el-tag v-if="item.status">在线</el-tag>-->
<!--                                </h4>-->
<!--                                <span class="cash">{{item.cash}}</span></div>-->
<!--                            <div class="tag-bar">-->
<!--                                <el-tag type="info" style="margin-right: 5px;" size="small"-->
<!--                                        v-for="(_item, index) in item.tags" :key="'item'+index">{{_item}}-->
<!--                                </el-tag>-->
<!--                            </div>-->
<!--                            <div class="gongsi-detial">-->
<!--                                <div>-->
<!--                                    <img :src="item.logo" alt="">-->
<!--                                    {{item.gongsiName}}-->
<!--                                </div>-->
<!--                                <div>-->
<!--                                    {{item.detail}}-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </el-tab-pane>-->
<!--                <el-tab-pane label="房地产·建筑" name="fourth3">-->
<!--                    <div class="position-box">-->
<!--                        <div class="position-list" v-for="(item, index) in positionList.IT" :key="index">-->
<!--                            <div class="header">-->
<!--                                <h4>{{item.name}}-->
<!--                                    <el-tag v-if="item.status">在线</el-tag>-->
<!--                                </h4>-->
<!--                                <span class="cash">{{item.cash}}</span></div>-->
<!--                            <div class="tag-bar">-->
<!--                                <el-tag type="info" style="margin-right: 5px;" size="small"-->
<!--                                        v-for="(_item, index) in item.tags" :key="'item'+index">{{_item}}-->
<!--                                </el-tag>-->
<!--                            </div>-->
<!--                            <div class="gongsi-detial">-->
<!--                                <div>-->
<!--                                    <img :src="item.logo" alt="">-->
<!--                                    {{item.gongsiName}}-->
<!--                                </div>-->
<!--                                <div>-->
<!--                                    {{item.detail}}-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </el-tab-pane>-->
<!--                <el-tab-pane label="医疗健康" name="fourth4">-->
<!--                    <div class="position-box">-->
<!--                        <div class="position-list" v-for="(item, index) in positionList.IT" :key="index">-->
<!--                            <div class="header">-->
<!--                                <h4>{{item.name}}-->
<!--                                    <el-tag v-if="item.status">在线</el-tag>-->
<!--                                </h4>-->
<!--                                <span class="cash">{{item.cash}}</span></div>-->
<!--                            <div class="tag-bar">-->
<!--                                <el-tag type="info" style="margin-right: 5px;" size="small"-->
<!--                                        v-for="(_item, index) in item.tags" :key="'item'+index">{{_item}}-->
<!--                                </el-tag>-->
<!--                            </div>-->
<!--                            <div class="gongsi-detial">-->
<!--                                <div>-->
<!--                                    <img :src="item.logo" alt="">-->
<!--                                    {{item.gongsiName}}-->
<!--                                </div>-->
<!--                                <div>-->
<!--                                    {{item.detail}}-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </el-tab-pane>-->
<!--            </el-tabs>-->
<!--            <div class="more-bar">-->
<!--                <a>查看更多</a>-->
<!--            </div>-->
<!--        </div>-->


        <div class="title-h2">
            <h2>
                <span>热门企业</span>
            </h2>
        </div>

        <div class="gongsi-bar">
            <div class="gongsi-list" v-for="(item, index) in gongsiList" :key="index">
                <div class="logo"
                     :style="item.num == 2 ?'background:#98C8FF' : item.num == 14 ? 'background:#9DC474' : ''">
                    <img :src="item.logo" alt="">
                </div>
                <h3>{{item.name}}</h3>
                <p>{{item.detail}}</p>
                <el-button class="btn" style="cursor: inherit"><span style="color: red">{{item.num}}</span>个在招聘职位</el-button>
            </div>
        </div>
<!--        <div class="more-bar">-->
<!--            <a>查看更多</a>-->
<!--        </div>-->

        <div class="footer">
            <img src="./assets/logo.png" alt="">
            <p>电话：0592-5516651</p>
            <p>@2010-2023厦门掌讯信息技术有限公司 <a href="https://beian.miit.gov.cn" target="_blank">闽ICP备17000432号 </a></p>
            <div style="
        width: 100%;
        border-top: 1px solid rgba(255, 255, 255, 0.55);"></div>
<!--            <div class="footer-list">-->
<!--                <div>友情链接：</div>-->
<!--                <div>厦门招聘</div>-->
<!--                <div>北京招聘</div>-->
<!--                <div>上海招聘</div>-->
<!--                <div>广州招聘</div>-->
<!--                <div>留学生招聘</div>-->
<!--                <div>店长直聘</div>-->
<!--                <div>兼职招聘</div>-->
<!--                <div>BOSS直聘</div>-->
<!--                <div>灵活用工</div>-->
<!--                <div>在职研究生招生信息网</div>-->
<!--                <div>成都招聘</div>-->
<!--                <div>更多</div>-->
<!--            </div>-->
        </div>
        <el-dialog
                title="发布信息"
                :visible.sync="modal"
                width="800px"
                :before-close="handleClose">
            <el-form ref="form" :model="form" label-width="120px" style="text-align: left" :rules="rules">
                <el-form-item label="职位名称：" prop="name">
                    <el-input v-model="form.name" maxLength="50"></el-input>
                </el-form-item>
                <el-form-item label="联系人：" prop="userName">
                    <el-input v-model="form.userName" maxLength="10"></el-input>
                </el-form-item>
                <el-form-item label="单位名称：" prop="comName" >
                    <el-input v-model="form.comName" maxLength="10"></el-input>
                </el-form-item>
                <el-form-item label="手机号："  prop="phone">
                    <el-input v-model="form.phone" maxLength="50"></el-input>
                </el-form-item>
                <el-form-item label="邮箱：" prop="email">
                    <el-input v-model="form.email" maxLength="50"></el-input>
                </el-form-item>
                <el-form-item label="内容："  prop="desc">
                    <el-input v-model="form.desc" maxLength="200"></el-input>
                </el-form-item>
                <el-form-item label="是否加急：">
                    <el-switch v-model="form.isSoom"></el-switch>
                </el-form-item>

            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="handleClose">取 消</el-button>
                <el-button type="primary" :loading="loading" @click="postMessageHander">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
    import 'swiper/dist/js/swiper'
    import 'swiper/dist/css/swiper.css'
    import Swiper from "swiper"

    /* 手机号*/
    function isPoneAvailable(str) {
        var myreg = /^[1][0,1,2,3,4,5,6,7,8,9][0-9]{9}$/
        if (!myreg.test(str)) {
            return false
        } else {
            return true
        }
    }


    /**
     * @param {string} email
     * @returns {Boolean}
     */
    function validEmail(email) {
        const reg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return reg.test(email)
    }

    var isEmail = (rule, value, callback) => {
        if (!value) {
            return callback(new Error("邮箱不能为空"));
        }
        if (!validEmail(value)) {
            callback(new Error("请输入正确的邮箱"));
        } else {
            callback();
        }
    };


    var isPhone = (rule, value, callback) => {
        if (!value) {
            return callback(new Error("手机号不能为空"));
        }
        if (!isPoneAvailable(value)) {
            callback(new Error("请输入正确的手机号"));
        } else {
            callback();
        }
    };
    export default {
        name: 'App',
        data() {
            return {
                loading:false,
                form: {
                    name: '',
                    userName: '',
                    comName: '',
                    phone: '',
                    email: '',
                    desc: '',
                    isSoom: false,
                },
                navX: 0,
                value: '',
                value1: '',
                curNavName: 'first',
                positionList: {
                    IT: [
                        {
                            name: '运维工程师',
                            status: true,
                            cash: '7-12K',
                            tags: ['厦门', '1-3年', '本科', '网络运维', '系统运维'],
                            gongsiName: '网宿科技',
                            detail: '互联网 | C轮',
                            logo: require('./assets/wangsu-logo.png')

                        },
                        {
                            name: '美工/摄影',
                            status: false,
                            cash: '4-8K',
                            tags: ['厦门', '经验不限', '大专'],
                            gongsiName: '创客智库',
                            detail: '互联网 | 不需要融资',
                            logo: require('./assets/jike-logo.png')
                        },
                        {
                            name: '产品经理',
                            status: false,
                            cash: '13-16K',
                            tags: ['厦门', '3-5年', '本科', 'B端产品'],
                            gongsiName: '法本',
                            detail: '计算机软件 | 已上市',
                            logo: require('./assets/faben-logo.png')
                        },
                        {
                            name: '网络销售',
                            status: true,
                            cash: '4-9K',
                            tags: ['三明', '1年以内', '大专'],
                            gongsiName: '律动',
                            detail: '广告/公关/会展 | 不需要融资',
                            logo: require('./assets/lvdong-logo.png')
                        },
                        {
                            name: '平面设计',
                            status: true,
                            cash: '10-15K',
                            tags: ['厦门', '3-5年', '本科', '家具用品', '天猫国际'],
                            gongsiName: '四月物语',
                            detail: '电子商务 | 不需要融资',
                            logo: require('./assets/siyue-logo.png')
                        },
                        {
                            name: '前端',
                            status: false,
                            cash: '4-7K',
                            tags: ['厦门', '经验不限', '本科'],
                            gongsiName: '道隆信息',
                            detail: '互联网 | 不需要融资',
                            logo: require('./assets/daolong-logo.png')
                        },
                        {
                            name: '资深文案',
                            status: false,
                            cash: '5-7K',
                            tags: ['厦门', '1-3年', '本科', '教育文案', '公众号文章'],
                            gongsiName: '齐物社',
                            detail: '在线教育 | 不需要融资',
                            logo: require('./assets/qiwu-logo.png')
                        },
                        {
                            name: '少儿编程老师',
                            status: false,
                            cash: '5-10K',
                            tags: ['厦门', '1-3年', '本科', 'Python', '机器人'],
                            gongsiName: '高维思创',
                            detail: '在线教育 | 未融资',
                            logo: require('./assets/gaoweisi.png')
                        },
                        {
                            name: '资深Java开发工程师',
                            status: true,
                            cash: '20-30K',
                            tags: ['厦门', '5-10年', '本科', '微服务架构'],
                            gongsiName: '网宿科技',
                            detail: '互联网 | C轮',
                            logo: require('./assets/wangsu-logo.png')
                        },
                    ]
                },
                gongsiList: [
                    {
                        logo: require('./assets/guangxia-logo.png'),
                        name: '广夏房地产',
                        detail: '房地产开发 | 100-499人 | 融资未公开',
                        num: 1
                    },
                    {
                        logo: require('./assets/benpu-logo.png'),
                        name: '本朴医药',
                        detail: '医疗健康 | 20-99人 | 未融资',
                        num: 13
                    },
                    {
                        logo: require('./assets/yuncheng-logo.png'),
                        name: '云秤数据',
                        detail: '软件·区块链 | 100-499人 | 融资未公开',
                        num: 1
                    },
                    {
                        logo: require('./assets/shanyin-logo.png'),
                        name: '善因文化',
                        detail: '文化/体育/娱乐 | 0-20人 | 未融资',
                        num: 1
                    },
                    {
                        logo: require('./assets/zhida-logo.png'),
                        name: '致达（北京）商贸',
                        detail: '互联网/电子商务 | 1-50人 | 外资（欧美）',
                        num: 2
                    },
                    {
                        logo: require('./assets/longzheng-logo.png'),
                        name: '隆政食品',
                        detail: '食品/饮料/就位 | 100-499人 | 融资未公开',
                        num: 4
                    },
                    {
                        logo: require('./assets/xinyuan-logo.png'),
                        name: '信阳圆创',
                        detail: '半导体/集成电器 | 1000-9999人 | 不需要融资',
                        num: 6
                    },
                    {
                        logo: require('./assets/huilong-logo.png'),
                        name: '慧峰生物',
                        detail: '制药 | 1-49人 | 不需要融资',
                        num: 14
                    },
                ],
                modal: false,
                missageList: [],
                rules: {
                    // 姓名
                    name: [
                        {
                            required: true,
                            message: "职位名称不能为空",
                            trigger: ["change",'blur'],
                        },
                    ],
                    // 姓名
                    userName: [
                        {
                            required: true,
                            message: "联系人不能为空",
                            trigger: ["change",'blur'],
                        },
                    ],
                    // 姓名
                    comName: [
                        {
                            required: true,
                            message: "企业名称不能为空",
                            trigger: ["change",'blur'],
                        },
                    ],
                    // 姓名
                    desc: [
                        {
                            required: true,
                            message: "职位描述名称不能为空",
                            trigger: ["change",'blur'],
                        },
                    ],
                    phone: [
                        {
                            required: true,
                            validator: isPhone,
                            trigger: ["change",'blur'],
                        },
                    ],
                    email: [
                        {
                            required: true,
                            validator: isEmail,
                            trigger: ["change",'blur'],
                        },
                    ]
                },
            }
        },
        watch: {
            '$route.path': {
                deep: true,
                handler(v, val) {
                    console.log(v, val);
                    setTimeout(() => {
                        window.scrollTo(0, 0);
                    }, 20)
                }
            },
        },
        mounted() {
            console.log(this.$route);

            let postionList = localStorage.getItem('postionList')
            console.log(postionList);
            if(postionList == '' || postionList == null){
                this.missageList = [
                    {
                    name: '财务管培生（2023届）',
                    userName: '洪小姐',
                    comName: '厦门松霖科技股份有限公司',
                    phone: '155****5123',
                    email: 'songling@aliyun.com',
                    desc: '岗位职责:\n' +
                        '1.掌握财务基础软件应用，熟悉国家会计法规，了解税务法规及相关税收政策\n' +
                        '2.财务轮岗：包括销售、采购、制造成本、费用审核及入帐、资金、税务、及财务管理，全面储备培训\n' +
                        '3.熟悉各岗位工作职责，善于发现问题，提出问题并解决问题，提高工作效率及输出结果',
                    isSoom: true,
                },{
                    name: '采购主管',
                    userName: '李小姐',
                    comName: '厦门谷萌',
                    phone: '176****5123',
                    email: '74515641@qq.com',
                    desc: '一：岗位职责\n' +
                        '1、负责采购信息的完整性，采购合同的评审，以及采购成本分析；\n' +
                        '2. 组织编制公司采购管理相关系统、流程，并根据实际情况不断优化、宣导，确保采购系统、流程顺畅，提高工作效率；\n' +
                        '3、负责交料进度控制和逾交督促，在保证产品质量和交货期的前提下，不断降低采购成本；\n' +
                        '4、负责及时与供应商联系沟通，掌握订单进度情况与材料备用及采购需求，确保正常供货；\n' +
                        '5、负责与供应单位往来明细帐的核算工作；\n' +
                        '6、能主动承担任务，做好相关的协调工作，配合部门完成公司的年度指标；\n' +
                        '7、指导下级开展日常工作；',
                    isSoom: false,
                },{
                    name: '宠物美容助理（厦门）',
                    userName: '杜女士',
                    comName: '广州福懋畜牧兽医服务有限公司',
                    phone: '177****5123',
                    email: 'axum@qq.com',
                    desc: '岗位要求\n' +
                        '1、有责任心,能吃苦耐劳,有奉献精神,沟通能力较好,心态积极乐观,服务意识强\n' +
                        '2、喜爱动物,有爱心,立志从事动物诊疗行业',
                    isSoom: false,
                },{
                    name: 'UI设计师',
                    userName: '梅女士',
                    comName: '中软国际',
                    phone: '155****5567',
                    email: 'chinaCode@amicable.com',
                    desc: '人员要求:\n' +
                        '一、年限说明\n' +
                        '1、设计相关专业毕业3-5年\n' +
                        '二、角色定义\n' +
                        '1、能根据项目需求完成整体UI风格设计（视觉风格定义，视觉页面扩展设计、视觉规范），\n' +
                        '2、能够独立完成整个项目视觉设计方案；\n' +
                        '3、提出构思新颖、有吸引力的创新设计；\n' +
                        '三、业务能力要求\n' +
                        '1、本科或以上学历，设计类相关专业（工业设计、艺术设计、动画设计、视觉传达）等专业背景；\n' +
                        '2、本科3年以上、硕士2年以上相关工作经验，精通UI设计流程，有扎实的用户界面和用户体验设计功底，丰富的设计创意；\n' +
                        '3、具有项目相关的成功案例，对设计趋势有一定的了解，可参与项目需求分析，在高级别人员的指导下完成部分视觉设计；\n' +
                        '4、如果能力特别突出，可打破学历、专业背景和从业经验年限的要求，通过专家团评审即认为符合该级别要求。\n' +
                        '四、设计技能要求\n' +
                        '1、精通PS、AI、sketch或XD等设计软件\n' +
                        '2、熟悉AE动效工具',
                    isSoom: false,
                },{
                    name: '插画师',
                    userName: '孙先生',
                    comName: '飞博共创',
                    phone: '175****9968',
                    email: '741441554@qq.com',
                    desc: '工作职责\n' +
                        '1、熟悉公司各个IP的人设特点，挖掘创意点并进行对应的图库开发\n' +
                        '2、具备1-2种擅长的绘画风格，并将其运用在图库开发的工作中\n' +
                        '3、根据客户及前端的需求，高效高质地完成商业项目中的插画设计\n' +
                        '4、能够及时总结自己的工作经验或对行业趋势的洞察，并分享给团队伙伴，共同成长\n' +
                        '5、负责图库授权和开发过程中的审核工作',
                    isSoom: false,
                },{
                    name: '客户经理',
                    userName: '张女士',
                    comName: '中国寿保',
                    phone: '135****5687',
                    email: 'inChine@ChRens.com',
                    desc: '1.有良好的沟通能力，抗压能力\n' +
                        '2.口齿伶俐，表达力强\n' +
                        '3.有业务拓展经历优先\n' +
                        '4.周一到周五上班，周末双休，根据个人业务能力薪资底薪2500上不封顶',
                    isSoom: false,
                },{
                    name: '销售经理',
                    userName: '王女士',
                    comName: '江国鹏电器设备有限公司',
                    phone: '138****6572',
                    email: 'qq120590133@qq.com',
                    desc: '岗位职责：\n' +
                        '1、主要负责与各建筑设计院、开发商及总包单位等的联络工作；\n' +
                        '2、桥架、母线槽、抗震支架等产品销售与推广；\n' +
                        '3、建立稳定的客户关系，维护现有客户资源，开发新客户；',
                    isSoom: false,
                }]
                localStorage.setItem('postionList', JSON.stringify(this.missageList))
            }else{
                this.missageList = JSON.parse(postionList)
            }

            new Swiper('.swiper-container1', {
                direction: 'horizontal', // 垂直切换选项
                //mousewheel: true, //滚轮
                autoplay: { //自动开始
                    delay: 2500, //时间间隔
                    disableOnInteraction: false, //*手动操作轮播图后不会暂停*
                },
                loop: true, // 循环模式选项
                // pagination: {
                //   el: '.swiper-pagination',
                //   clickable: true, // 分页器可以点击
                // },
                // 如果需要前进后退按钮
                navigation: {
                    nextEl: '.swiper-button-next1',
                    prevEl: '.swiper-button-prev1',
                },
            })
            new Swiper('.swiper-container2', {
                direction: 'horizontal', // 垂直切换选项
                //mousewheel: true, //滚轮
                // autoplay: { //自动开始
                //     delay: 2500, //时间间隔
                //     disableOnInteraction: false, //*手动操作轮播图后不会暂停*
                // },
                slidesPerView: 4,
                loop: true, // 循环模式选项
                // pagination: {
                //   el: '.swiper-pagination',
                //   clickable: true, // 分页器可以点击
                // },
                // 如果需要前进后退按钮
                navigation: {
                    nextEl: '.swiper-button-next2',
                    prevEl: '.swiper-button-prev2',
                },
            })
        },
        methods: {
            handleClose() {
                this.$refs.form.resetFields()
                this.modal = false
            },
            setModalShow() {
                this.modal = true
            },
            postMessageHander(){

                this.$refs.form.validate((valid)=>{
                    if(valid){
                        this.loading = true
                        setTimeout(()=>{

                            this.loading = false
                            this.missageList.unshift(JSON.parse(JSON.stringify(this.form)))
                            this.$refs.form.resetFields()
                            localStorage.setItem('postionList', JSON.stringify(this.missageList))
                            this.modal = false
                            this.form = {
                                name: '',
                                userName: '',
                                comName: '',
                                phone: '',
                                email: '',
                                desc: '',
                                isSoom: false,
                            }

                            this.$message({
                                showClose: true,
                                message: '恭喜你，信息发布成功',
                                type: 'success'
                            });
                        },500)
                    }
                })
            },
            navEnter(navData) {
                if (navData.name == '产品' || navData.name == '关于我们') {
                    this.navSubState = true
                    this.subNavType = navData.id
                    this.navX = document.getElementById(navData.id).getBoundingClientRect().left + 22
                }
            },
            routerChange(navData) {
                if (navData.name == '产品' || navData.name == '关于我们') {
                    return
                } else {
                    this.curNavName = navData.id;
                    this.$router.push(`/${navData.id}`)
                }
            },
            subNavGoto(subNavData) {
                this.curNavName = subNavData.fName;
                if (this.subNavType == 'product') {
                    this.$router.push(`/${subNavData.id}${subNavData.productName}`)
                } else {
                    this.$router.push(`/${subNavData.id}`)
                }
            }
        }
    }
</script>

<style>
    * {
        padding: 0;
        margin: 0;
        list-style: none;
    }

    .slide-fade-enter, .slide-fade-leave-to
        /* .slide-fade-leave-active for below version 2.1.8 */
    {
        opacity: 0;
        transition: all .2s ease;
    }

    #app {
        font-family: PingFang HK, helvetica neue, hiragino sans gb, arial, microsoft yahei ui, microsoft yahei, simsun, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        color: #2c3e50;
        background-color: #f3f4f7;
    }

    pre, input, button, div, p, h1, h2, h3, h4, h5, h6, span, ul, li, a {
        font-family: PingFang HK, helvetica neue, hiragino sans gb, arial, microsoft yahei ui, microsoft yahei, simsun, sans-serif;
    }

    html, body {
        position: relative;
        width: 100%;
        height: 100%;
    }

</style>

<style lang="scss" scoped>
    .message-bar {
        width: 1200px;
        display: flex;
        margin: 30px auto;
        background-color: #fff;
        height: 400px;
        display: flex;
        flex-direction: column;
        text-align: left;
        box-sizing: border-box;
        padding: 15px 20px;
        >h3{
            height: 50px;
            line-height: 50px;
        }
    }

    .message-box{
        display: flex;
        width: 100%;
        flex-direction: column;
        overflow-y: auto;
    }
    .message-list{
        display: flex;
        height: 100px;
        width: 100%;
        border-top: 1px solid #dfdfdf;
        border-bottom: 1px solid #dfdfdf;
        justify-content: space-between;
        padding: 15px 20px;
        box-sizing: border-box;
        font-size: 14px;
        transition: .3s all;
        cursor: pointer;
        h3{
            font-weight: normal;
            padding: 0;
            margin: 0;
            color: #000;
        }
        &:hover{
            box-shadow: 0 0 10px 5px rgba(0,0,0,.05);
            transition: .3s all;
        }
        >div{
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: 33%;
        }
        >p{
            width: 33%;
        }
    }
    .footer-list {
        width: 1200px;
        font-size: 14px;
        color: rgba(255, 255, 255, 0.55);
        display: flex;
        height: 49px;
        align-items: center;
        justify-content: space-between;
    }

    .footer {
        margin-top: 60px;
        background: #323438;
        display: flex;
        flex-direction: column;
        align-items: center;

        img {
            margin-top: 30px;
            margin-bottom: 30px;
        }

        p {
            font-size: 14px;
            line-height: 20px;
            color: rgba(255, 255, 255, 0.55);
            margin-bottom: 20px;

            span {
                margin-left: 50px;
            }

            a {
                font-style: normal;
                text-decoration: none;
                color: rgba(255, 255, 255, 0.55);
            }
        }
    }

    .gongsi-bar {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 1200px;
        margin: 30px auto 0;

        .gongsi-list {
            width: 289px;
            height: 236px;
            background: #FFFFFF;
            border-radius: 10px;
            padding-top: 13px;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-bottom: 14px;

            .logo {
                width: 72px;
                height: 72px;
                background: #FFFFFF;
                border: 1px solid #E7E7E7;
                border-radius: 72px;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            h3 {
                margin-top: 13px;
                font-size: 16px;
                line-height: 22px;
                color: #000000;
            }

            p {
                margin-top: 26px;
                font-size: 13px;
                line-height: 18px;
                color: #333333;
            }

            .btn {
                font-style: normal;
                font-size: 14px;
                margin-top: 21px;
            }
        }
    }

    .more-bar {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 1200px;
        margin: 30px auto 0;

        a {
            width: 180px;
            height: 40px;
            background: rgba(0, 134, 255, 0.06);
            border-radius: 6px;
            font-size: 14px;
            color: #0086FF;
            line-height: 40px;
            text-align: center;
        }
    }

    .position-list {
        display: flex;
        flex-direction: column;
        background-color: #fff;
        border-radius: 8px;
        width: 391px;
        height: 137px;
        justify-content: space-between;
        box-sizing: border-box;
        margin-bottom: 14px;

        .header {
            padding: 15px 20px 0;

            .cash {
                color: rgba(255, 108, 99, 1)
            }
        }

        .header, .gongsi-detial {
            display: flex;
            justify-content: space-between;
        }

        .gongsi-detial {
            height: 48px;
            align-items: center;
            padding: 0 20px;
            background-color: rgba(222, 239, 255, .2);

            div {
                display: flex;
                align-items: center;
                font-size: 13px;
                color: rgba(102, 102, 102, 1);

                img {
                    margin-right: 5px;
                }
            }
        }

        .tag-bar {
            display: flex;
            justify-content: flex-start;
            padding: 0 20px;
        }
    }

    .position-box {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .position-bar {
        width: 1200px;
        margin: 25px auto 0;
    }

    .title-h2 {
        width: 1200px;
        margin: 60px auto 0;
        display: flex;
        align-content: center;
        justify-content: center;

        h2 {
            font-size: 34px;
            color: #333;
            position: relative;
            height: 48px;
            width: 140px;

            &:after {
                content: '';
                position: absolute;
                width: 97px;
                height: 15px;
                border-right: 4px;
                background-color: #cfe4f8;
                left: -10px;
                bottom: 5px;
                z-index: 0;
            }

            span {
                position: absolute;
                z-index: 1;
                width: 140px;
                left: 0;
            }
        }
    }


    .title-bar {
        height: 66px;
        width: 100%;
        background: #333638;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .content-box {
        width: 1200px;
        height: 100%;
        display: flex;
        justify-content: space-between;
    }

    .nav-box {
        display: flex;
        height: 100%;
        align-items: center;

        img.logo {
            width: 90px;
            height: 25px;
            margin-right: 35px;
        }

        .loc-box {
            img {
                width: 15px;
            }

            color: #319dff;
            font-size: 14px;

            span {
                margin-left: 5px;
                font-size: 12px;
                color: #fff;
            }
        }

        .nav {
            display: flex;
            color: #ffffff;

            div {
                padding: 0 15px;
                text-align: center;
                cursor: inherit;
                transition: .5s all;

                &:hover {
                    color: #319dff;
                    transition: .2s all;
                }

                &.cur {
                    color: #319dff;
                    transition: .2s all;
                }
            }
        }
    }

    .nav-right {
        display: flex;
        justify-content: flex-end;
        color: #fff;
        align-items: center;

        div {
            margin-left: 15px;
        }

        .my-btn {
            background-color: rgba(0, 134, 255, .15);
            border: 1px solid #0086ff;
            border-radius: 4px;
            color: #fff;
        }
    }

    .banner-box {
        height: 130px;
        background: url("./assets/banner-bg.png") no-repeat 100%;
        background-size: cover;
        display: flex;
        align-items: center;
        justify-content: center;

        .banner {
            width: 1200px;
            height: 100%;
            position: relative;

            .banner-ren {
                position: absolute;
                left: 5px;
                top: 20px;
            }

            .banner-text {
                position: absolute;
                left: 150px;
                top: 45px;
            }
        }

    }

    .search-bar {
        display: flex;
        width: 900px;
        height: 56px;
        background-color: #0086ff;
        border-radius: 8px;
        margin: 10px auto;
        align-items: center;
        padding-left: 2px;
        box-sizing: border-box;

        .search-box {
            height: 52px;
            background-color: #fff;
            border-radius: 8px;
            width: 780px;
            display: flex;
            align-items: center;

            ::v-deep .el-input__inner {
                border: none;
            }
        }

        a {
            width: 118px;
            color: #fff;
            font-size: 20px;
        }
    }

    .fast-search-bar {
        display: flex;
        height: 48px;
        align-items: center;
        color: rgba(0, 134, 255, 1);
        width: 900px;
        margin: 0 auto 10px;

        span {
            margin-left: 20px;
        }

        p {
            color: #2c3e50;
        }
    }

    .content-banner-bar {
        display: flex;
        justify-content: space-between;
        height: 336px;
        width: 1200px;
        margin: 0 auto;
    }

    .search-list {
        width: 386px;
        height: 336px;
        display: flex;
        flex-direction: column;
        background-color: #fff;
        border-radius: 8px;
        padding: 14px 18px;
        box-sizing: border-box;

        > div {
            display: flex;
            width: 100%;
            justify-content: space-between;
            height: 40px;
            align-content: center;

            > div {
                display: flex;

                p {
                    font-size: 14px;
                    font-weight: bold;
                }

                span {
                    margin-left: 20px;
                    color: #666;
                    cursor: inherit;
                    transition: .2s all;

                    &:hover {
                        color: #0086ff;
                        transition: .2s all;
                    }
                }
            }

            ::v-deep i {
                color: #999;
            }
        }

        .search-list-footer {
            margin: 0 -18px;
            border-top: 1px solid #dfdfdf;
            width: 386px;
            padding: 15px 18px 0;
            box-sizing: border-box;
            color: #0086ff;

            > div {
                ::v-deep i {
                    color: #0086ff;
                    width: 20px;
                    height: 20px;
                    background-color: rgba(0, 134, 255, .06);
                    margin-left: 10px;
                    display: flex;
                    align-content: center;
                    justify-content: center;
                }
            }
        }
    }

    .content-banner-center {
        width: 463px;

        img {
            width: 100%;
        }
    }

    .content-banner-right {
        width: 332px;

        img {
            width: 100%;
        }
    }
</style>
