import Vue from 'vue'
import Router from 'vue-router'

//添加以下代码
const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
Vue.use(Router)

export const constantRoutes = [
  {
    path: "*",
    redirect: '/index',
  },
  {
    path: '/index',
    component: (resolve) => require(['../page/index/index.vue'], resolve),
  },
]
